
/* eslint-disable */
import CloudFun, {
  Model,
  defineComponent,
  ref,
  onMounted,
  reactive,
  computed,
  Sorting,
  SortOrder,
  Condition,
  Operator,
  LogicalConnective,
} from "@cloudfun/core";
import { useRouter, useRoute, onBeforeRouteLeave } from "vue-router";
import FileUploader from "@/cloudfun/components/FileUploader.vue";
import UploadAdapterPlugin from "@/global-components/ckeditor/upload-adapter-plugin";
import ClassicEditor from "@/global-components/ckeditor/classic-editor";
import formatDate from "xe-utils/toDateString";
import "@/global-components/ckeditor/styles.css";
import { DatePicker } from "v-calendar";
import VueTagsInput from "@sipec/vue3-tags-input";
import {
  required,
  //   minLength,
  //   maxLength,
  //   email,
  //   url,
  //   integer,
} from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { toRefs, watch, nextTick } from "vue";
import { deepEqual } from "fast-equals";
import _, { truncate } from "lodash";
import { v1 as uuidv1 } from "uuid";
import SliderEditor from "@/cloudfun/components/SliderEditor.vue";
import Grid from "@/cloudfun/components/Grid.vue";
import SelectBox, {
  SelectBoxOptions,
} from "@/cloudfun/components/SelectBox.vue";
import moment from "moment";
import Loading from "vue-loading-overlay";
export default defineComponent({
  components: {
    FileUploader,
    VueTagsInput,
    SliderEditor,
    SelectBox,
    Grid,
    DatePicker,
  },
  props: {
    id: {
      type: Number,
    },
  },
  setup(props) {
    const router = useRouter();
    const route = useRoute();
    const range = ref({
      start: moment(new Date()).format("YYYY-MM-DD"),
      end: moment(new Date()).add(7, "d").format("YYYY-MM-DD"),
    });
    const model = CloudFun.current?.model;
    const hasChanged = ref(false);
    const grid = ref<any>({});
    const original = reactive({ formData: {} });
    const current = ref<any>({});
    const hotel = ref<any>({});
    const masterRow = ref<any>({});
    const rooms = ref<any>({});
    const roomItem = ref<any>({});
    const condictions = ref<Condition[]>([]);
    const params = reactive({
      insertRows: [],
      updateRows: [],
      deleteRows: [],
    });
    const form = reactive({
      formData: {
        Id: 0,
        Name: null,
        HotelId: route.query.hotelId,
        Additional: null,
        Notice: {
          Content: "",
        },
        Introduction: {
          Content: "",
        },
        Remark: null,
        PhotoGallery: {
          Pictures: null,
        },
        Specification: {
          Content: "",
        },
      },
    });
    const permission = reactive({ isEditing: true, isCreate: true });

    const gridOptions = {
      title: "房型優惠項目",
      canCreateRow: true,
      canUpdateRow: true,
      canDeleteRow: true,
      multiselect: true,
      toolbarConfig: {
        custom: false,
        refresh: true,
      },
      sortConfig: { defaultSort: { field: "StartTime", orderby: "asc" } },
      columns: [
        {
          field: "StartTime",
          title: "銷售日期",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }: any) =>
            cellValue ? formatDate(new Date(cellValue), "yyyy/MM/dd") : "",
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "date" },
          },
        },

        {
          field: "MarketPrice",
          title: "定價",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "integer" },
          },
        },
        {
          field: "SalePrice",
          title: "促銷價",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "integer" },
          },
        },
        {
          field: "Cost",
          title: "成本",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "integer" },
          },
        },
        {
          field: "ProfitAmount",
          title: "利潤",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "integer" },
          },
        },
        {
          field: "ProfitRate",
          title: "利潤率(%)",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "integer" },
          },
        },

        {
          field: "StockQty",
          title: "配售數量",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "integer" },
          },
        },
      ],
      promises: {
        query: model
          ? (params: any) => {
              console.log("params", params);
              condictions.value.length = 0;
              condictions.value.push(
                new Condition(
                  "HotelRoomSaleItemId",
                  Operator.Equal,
                  route.query.id
                )
              );

              condictions.value.push(
                new Condition(
                  "StartTime",
                  Operator.GreaterThanOrEqual,
                  moment(range.value.start).format("YYYY-MM-DD")
                )
              );

              condictions.value.push(
                new Condition(
                  "StartTime",
                  Operator.LessThanOrEqual,
                  moment(range.value.end).format("YYYY-MM-DD")
                )
              );
              // if (condictions.value)
              params.condition = new Condition(condictions.value);
              params.Sorting = new Sorting("StartTime", SortOrder.Ascending);

              return model.dispatch("hotelEvent/query", params);
            }
          : undefined,
        queryAll: model
          ? () =>
              model.dispatch("hotelEvent/query", {
                keyword: grid.value.keyword,
              })
          : undefined,
        save: model
          ? (params: any) => {
              if (permission.isEditing || permission.isCreate)
                return model.dispatch("hotelEvent/save", params);
              else return Promise.resolve();
            }
          : undefined,
      },
      modalConfig: { height: "Auto", width: "1024" },
    };

    const formOptions = {
      items: [
        {
          field: "StartTime",
          title: "銷售日期",
          span: 24,

          itemRender: {
            name: "$input",
            props: {
              disabled: true,
              type: "date",
              placeholder: "銷售日期",
            },
          },
        },
        {
          field: "MarketPrice",
          title: "定價",
          span: 12,
          itemRender: {
            name: "$input",
            autofocus: ".vxe-input--inner",
            placeholder: "請點擊輸入...",
            props: {
              clearable: true,
              type: "integer",
              min: 0,
              align: "right",
              // onChange: costChange,
            },
          },
        },

        {
          field: "Cost",
          title: "成本",
          showHeaderOverflow: true,
          showOverflow: true,
          span: 12,
          sortable: false,
          itemRender: {
            name: "$input",
            autofocus: ".vxe-input--inner",
            placeholder: "請點擊輸入...",
            props: {
              clearable: true,
              type: "integer",
              min: 0,
              align: "right",
              // onChange: costChange,
            },
          },
        },
        {
          field: "SalePrice",
          title: "銷售價格",
          showHeaderOverflow: true,
          showOverflow: true,
          span: 12,
          sortable: false,
          itemRender: {
            name: "$input",
            autofocus: ".vxe-input--inner",
            placeholder: "請點擊輸入...",
            props: {
              clearable: true,
              type: "integer",
              min: 0,
              align: "right",
              // onChange: priceChange,
            },
          },
        },

        {
          field: "StockQty",
          title: "配售數量",
          span: 12,
          itemRender: {
            name: "$input",
            autofocus: ".vxe-input--inner",
            placeholder: "請點擊輸入...",
            props: {
              clearable: true,
              type: "integer",
              min: 0,
              align: "right",
              readonly: "true",
            },
          },
        },

        {
          span: 24,
          align: "right",
          itemRender: {
            name: "$buttons",
            children: [
              { props: { type: "submit", content: "確定", status: "primary" } },
              { props: { type: "reset", content: "重置" } },
            ],
          },
        },
      ],
      rules: {
        // Number: [
        //   {
        //     type: "string",
        //     max: 128,
        //     required: true,
        //     message: "請輸入編號",
        //   },
        // ],
      },
    };

    const title = computed(() => {
      return form.formData.Id === 0 ? "新增" : "編輯";
    });

    onBeforeRouteLeave((to, from) => {
      console.log("hasChanged", _.isEqual(original.formData, form.formData));
      if (hasChanged.value) {
        const answer = window.confirm("尚未儲存，確定離開？");
        return answer;
      }
    });

    watch(form.formData, (first, second) => {
      if (!_.isEqual(original.formData, second)) hasChanged.value = true;
    });

    watch(current, (first, second) => {
      console.log("current", current.value);
      grid.value.refresh();
    });

    watch(range, (first, second) => {
      console.log("current", range.value);
      grid.value.refresh();
    });

    const attributes = computed(() => {
      return roomItem.value.Attributes.map(
        (el: any) => el.AttributeCategory.Name
      );
    });

    const loadData = () => {
      if (route.query && route.query.id) {
        condictions.value.push(
          new Condition("Id", Operator.Equal, route.query.id)
        );

        const queryParams = {
          condition: new Condition(condictions.value),
        };
        model?.dispatch("hotelRoomSaleItem/query", queryParams).then(
          (payload) => {
            console.log("payload", payload);
            if (payload) roomItem.value = payload[0];
          },
          (failure) => {
            console.log("failure", failure);
          }
        );
      }
    };

    onMounted(() => {
      loadData();
    });

    // modal 執行 新增or修改
    const save = () => {
      // validate.value.$touch();
      // console.log(validate.value);
      // if (validate.value.$invalid) return;
      // if (form.formData.Id === 0) params.insertRows = [form.formData];
      // else {
      //   const postData = _.cloneDeep(form.formData);
      //   if (form.formData.PhotoGallery.length > 0) {
      //     postData.PhotoGallery = form.formData.PhotoGallery[0];
      //   }
      //   params.updateRows = [postData];
      // }
      // model
      //   .dispatch("hotelEvent/save", params)
      //   .then(
      //     (resp) => {
      //       console.log("update success", resp);
      //       CloudFun.send("info", {
      //         subject: form.formData.Id == 0 ? "新增成功" : "更新成功",
      //         content:
      //           form.formData.Id == 0 ? "飯店資料新增完成" : "飯店資料更新完成",
      //       });
      //       hasChanged.value = false;
      //       router.back();
      //     },
      //     (failure) => {
      //       console.log("update error", failure);
      //       CloudFun.send("error", { subject: "操作失敗！", content: failure });
      //     }
      //   )
      //   .finally();
      // e.preventDefault();
    };

    function changeItem(room: any) {
      console.log("room", room);
      current.value = room;
    }

    // const switchChange = async (row, $event) => {
    //   const payload = await model?.dispatch("hotelRoomSaleItem/find", row.Id);
    //   if (payload) {
    //     payload.Promoted = $event.value;
    //     await model?.dispatch("hotelRoomSaleItem/update", payload);
    //   }
    // };

    const initData = (row: any) => {
      row.Id = 0;
      row.Number = "";
      row.Published = true;
      row.HotelRoomId = current.value.Id;
      row.Adult = 2;
      row.Child = 0;
      row.SalesQuantity = 0;
      row.StartDate = new Date();
    };

    const addNew = () => {
      masterRow.value = {
        Id: 0,
        Number: "",
        Published: true,
        HotelRoomId: current.value,
      };

      grid.value.addNewRow(masterRow);
    };

    return {
      initData,
      changeItem,
      form,
      uploadAction: `${process.env.VUE_APP_BACKEND_URL}/api/files`,
      save,
      router,
      route,
      gridOptions,
      hotel,
      current,
      title,
      permission,
      rooms,
      grid,
      formOptions,
      masterRow,
      addNew,
      range,
      loadData,
      roomItem,
      attributes,
    };
  },
  methods: {
    back() {
      this.$router.back();
    },
    onGridModalResize(size: any) {
      // this.sliderWidth = `${size.width - 100}px`;
    },
    async onGridEdit(row: any, callback: any) {
      const entity = row.Id
        ? await this.$model.dispatch("hotelEvent/find", row.Id)
        : undefined;
      if (entity) Object.assign(row, entity);
      callback();
    },
    onGridRemoveSelectedRows(rows: any, callback: any) {
      cash("#batch-dropdown").dropdown("hide");
      callback();
    },
    // calendar() {
    //   this.$router.push({
    //     path: "calendar",
    //     query: { id: this.route.query.id },
    //   });
    // },
    roomCalendar() {
      console.log("current", this.current.Id);
      this.$router.push({
        path: "calendar",
        query: { id: this.route.query.id, roomId: this.current.Id },
      });
    },
    calendar(row: any) {
      console.log("row", row);
      this.$router.push({
        path: "calendar",
        query: {
          id: this.route.query.id,
          roomId: this.current.Id,
          roomSaleId: row.Id,
        },
      });
    },
  },
});
